import BaseBean from "@/utils/BaseBean";

export interface IQptzdCardDataObj {
    utilInst:QptzdCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class QptzdCardUtil extends BaseBean{
    public dataObj:IQptzdCardDataObj;

    constructor(proxy:any,dataObj:IQptzdCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        //this.dataObj.networkData=await this.utils.OrderProviderApi.buildNetworkData();
    }
    //确定生成启票通知单
    public genQptzdAction():void{
        this.utils.Tools.configBox({
            message:'确定生成<span style="color: red;font-weight: bold;font-size: 20px;">'+this.dataObj.form.ddh+'</span>的启票通知单吗?',
            distinguishCancelAndClose:true,
            sureFn:async ()=> {
                let res=await this.utils.OrderProviderApi.genQptzdAction({params:{childOrderId:this.dataObj.form.childOrderId}});
                if(res.result){
                    this.utils.Tools.success();
                    this.proxy.engine.engineParams.ownerComp.queryHandler();
                    this.proxy.dialogInst.dialogVisible=false;
                }
            }
        });
    }
}